<template>
  <div>
    <section class="section is-main-section" v-if="model">
      <h1 class="title">
        {{ model.id ? "ແກ້ໄຂຂໍ້ມູນລູກຄ້າ" : "ເພີ່ມລູກຄ້າໃໝ່" }}
      </h1>

      <div class="columns">
        <div class="column">
          <card-component
            class="has-table has-mobile-sort-spaced"
            style="padding: 1em"
          >
            <b-field label="ເບີໂທ">
              <b-input
                v-model="model.tel"
                placeholder="ເບີໂທ"
                maxlength="10"
                required
              />
            </b-field>
            <b-field label="ຊື່">
              <b-input v-model="model.name" placeholder="ຊື່" required />
            </b-field>
            <b-field label="ລະຫັດຜ່ານ">
              <b-input
                type="password"
                v-model="model.password"
                password-reveal
                required
              >
              </b-input>
            </b-field>

            <div class="columns">
              <div class="column">
                <b-field label="ເພດ" class="has-check">
                  <radio-picker
                    v-model="model.sex"
                    :options="{
                      female: 'ຍິງ',
                      male: 'ຊາຍ',
                    }"
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field label="COD" class="has-check">
                  <b-checkbox
                    v-model="model.isCod"
                    :native-value="false"
                    :type="'boolean'"
                  >
                    COD
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <b-field label="ແຂວງເມືອງບ້ານ" v-if="model.isCod">
              <b-select v-model="model.province" placeholder="ແຂວງ" required>
                <option
                  v-for="(province, index) in provinces"
                  :key="index"
                  :value="province"
                >
                  {{ province.name }}
                </option>
              </b-select>
              <b-select v-model="model.district" placeholder="ເມືອງ" required>
                <option
                  v-for="(district, index) in getDistricts()"
                  :key="index"
                  :value="district"
                >
                  {{ district.name }}
                </option>
              </b-select>
              <b-input v-model="model.village" placeholder="ບ້ານ" required />
            </b-field>
            <b-field label="ທີ່ຢູ່" v-if="model.isCod">
              <b-input
                type="textarea"
                :rows="2"
                v-model="model.address"
                placeholder="ທີ່ຢູ່"
              />
            </b-field>
            <hr />
            <b-button class="is-success" @click="save()" icon-left="check">
              ບັນທຶກ
            </b-button>
          </card-component>
        </div>
        <div class="column">
          <card-component
            v-if="model.isCod"
            class="has-table has-mobile-sort-spaced"
            style="padding: 1em"
          >
            <table class="table is-fullwidth is-bordered">
              <thead>
                <tr>
                  <th colspan="4">ເລກບັນຊີ COD</th>
                </tr>
                <tr>
                  <th style="width: 5%">#</th>
                  <th style="width: 30%">ທະນາຄານ</th>
                  <th>ເລກບັນຊີ</th>
                  <th>
                    <b-button
                      @click="addAccount()"
                      icon-left="plus"
                      class="is-success"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(account, index) in model.customer_account"
                  :key="index"
                >
                  <td style="width: 5%">{{ index + 1 }}</td>
                  <td style="width: 30%">
                    <b-select
                      v-model="model.customer_account[index].bank"
                      placeholder="ທະນາຄານ"
                      required
                    >
                      <option
                        v-for="(bank, index) in banks"
                        :key="index"
                        :value="bank"
                      >
                        {{ bank.code }}
                      </option>
                    </b-select>
                  </td>
                  <td>
                    <b-field>
                      <b-select v-model="model.customer_account[index].ccy">
                        <option v-for="c in currencies" :key="c">
                          {{ c }}
                        </option>
                      </b-select>
                      <b-input
                        v-model="model.customer_account[index].account"
                        placeholder="ເລກບັນຊີ"
                        maxlength="18"
                      />
                    </b-field>
                  </td>
                  <td>
                    <b-button
                      @click="removeAccount(index)"
                      class="is-danger"
                      icon-left="trash-can"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </card-component>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import RadioPicker from "@/components/RadioPicker";
import axios from "axios";
import { get } from "vuex-pathify";
import swal from "sweetalert2";
import CardComponent from "@/components/CardComponent";
export default {
  components: { RadioPicker, CardComponent },
  computed: {
    user: get("user"),
    banks: get("banks"),
  },
  data() {
    return {
      id: null,
      model: null,
      provinces: [],
      alldistricts: [],
      currencies: ["LAK", "THB", "USD"],
    };
  },
  mounted() {
    axios.get("provinces").then((res) => {
      this.provinces = res;
    });
    axios.get("districts").then((res) => {
      this.alldistricts = res;
    });
    this.id = this.$route.params["id"] * 1;
    this.get();
  },
  methods: {
    get() {
      if (this.id === 0) {
        this.model = {
          sex: "female",
          customer_account: [
            {
              account: null,
              bank: this.banks[0],
              ccy: "LAK",
            },
            {
              account: null,
              bank: this.banks[0],
              ccy: "THB",
            },
            {
              account: null,
              bank: this.banks[0],
              ccy: "USD",
            },
          ],
        };
      } else {
        axios.get(`customers/get/${this.id}`).then((item) => {
          this.model = item;
          if (item.district)
            this.model.province = this._.find(
              this.provinces,
              (p) => p.id === item.district.province_id
            );
          this.model.district = this._.find(
            this.alldistricts,
            (d) => d.id === item.district_id
          );

          this.model.customer_account = item.customer_account;
        });
      }
    },
    removeAccount(index) {
      this.model.customer_account.splice(index, 1);
    },
    addAccount() {
      this.model.customer_account.push({
        bank: this.banks[0],
        ccy: "LAK",
        account: null,
      });
    },
    getDistricts() {
      return this.model.province
        ? this._.filter(
            this.alldistricts,
            (d) => d.province_id == this.model.province.id
          )
        : this.alldistricts;
    },
    save() {
      let req;
      this.model.user_id = this.user.id;
      if (this.model.tel === undefined || !this.model.tel.match(/^\d{6,}$/g)) {
        swal.fire("ເບີໂທບໍ່ຖືກຕ້ອງ", "", "error");
        return;
      }

      if (
        this.model.password === undefined ||
        this.model.password.trim().length === 0
      ) {
        swal.fire("ລະຫັດຜ່ານບໍ່ສາມາດໃສ່ຍະຫວ່າງ", "", "error");
        return;
      }

      if (this.model.isCod) {
        let valid = true;
        for (
          let index = 0;
          index < this.model.customer_account.length;
          index++
        ) {
          const o = this.model.customer_account[index];
          if (o.account) {
            if (o.account.match(/[^\d]/)) {
              this.$buefy.snackbar.open({
                message: `ເລກບັນຊີຄວນມີແຕ່ຕົວເລກເທົ່ານັ້ນ ${o.account}`,
                queue: false,
              });
              valid = false;
            }
            if (
              o.bank.code === "BCEL" &&
              o.account.length !== 13 &&
              o.account.length !== 18
            ) {
              this.$buefy.snackbar.open({
                message: `ເລກບັນຊີ ${o.bank.code} ຄວນມີ ຄວາມຍາວ 13 ຫຼື 18 ຕົວເລກ ${o.account}`,
                queue: false,
              });
              valid = false;
            }
            if (o.bank.code === "BCEL" && o.account.length === 18) {
              if (o.ccy === "LAK" && o.account.substr(5, 2) !== "00") {
                this.$buefy.snackbar.open({
                  message: `ເລກບັນຊີ ${o.bank.code} ${o.ccy} ບໍ່ຖືກຕ້ອງ ຕໍາແໜ່ງຕົວເລກທີ 5-6 ຕ້ອງເປັນ 00`,
                  queue: false,
                });
                valid = false;
              } else if (o.ccy === "THB" && o.account.substr(5, 2) !== "02") {
                this.$buefy.snackbar.open({
                  message: `ເລກບັນຊີ ${o.bank.code} ${o.ccy} ບໍ່ຖືກຕ້ອງ ຕໍາແໜ່ງຕົວເລກທີ 5-6 ຕ້ອງເປັນ 02`,
                  queue: false,
                });
                valid = false;
              } else if (o.ccy === "USD" && o.account.substr(5, 2) !== "01") {
                this.$buefy.snackbar.open({
                  message: `ເລກບັນຊີ ${o.bank.code} ${o.ccy} ບໍ່ຖືກຕ້ອງ ຕໍາແໜ່ງຕົວເລກທີ 5-6 ຕ້ອງເປັນ 01`,
                  queue: false,
                });
                valid = false;
              }
            }
          }
        }

        //find duplicated ccy
        for (let index = 0; index < this.currencies.length; index++) {
          const currency = this.currencies[index];

          const duplicated = this._.filter(
            this.model.customer_account,
            (a) => a.ccy === currency
          );
          console.log(
            "this.model.customer_account",
            this.model.customer_account
          );
          console.log("duplicated", duplicated);
          if (duplicated && duplicated.length > 1) {
            this.$buefy.snackbar.open({
              message: `ສະກຸນເງິນ 1 ຕໍ່ 1 ບັນຊີ`,
              queue: false,
            });
            valid = false;
          }
        }
        if (!valid) {
          return;
        }

        const accs = this._.filter(this.model.customer_account, function (o) {
          return o.account != null && o.account.trim().length > 0;
        });
        console.log(accs);
        if (accs.length === 0) {
          swal.fire("ລູກຄ້າ COD ກະລຸນາ ປ້ອນເລກບັນຊີ", "", "error");
          return;
        }
      }

      if (!this.model.id) {
        this.model.code = this.model.tel;
        //console.log(this.model);
        req = axios.put("customers", this.model);
      } else {
        req = axios.post("customers", this.model);
      }
      req
        .then((res) => {
          console.log(res);
          swal.fire("ສຳເລັດ", "", "success");
          if (this.id === 0) {
            this.$router.back();
          } else {
            this.get();
          }
        })
        .catch((e) => {
          swal.fire("ພົບບັນຫາ", e, "error");
        });
    },
  },
};
</script>